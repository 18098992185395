import React from 'react';


function Settings({groups, setGroups}) {

  const handleMemberChange = (groupIndex, memberIndex, field, value) => {
    // Logic to update member information including the optedIn status
    const updatedGroups = groups.map((group, gIndex) => {
      if (gIndex === groupIndex) {
        const updatedMembers = group.members.map((member, mIndex) => {
          if (mIndex === memberIndex) {
            return { ...member, [field]: value };
          }
          return member;
        });
        return { ...group, members: updatedMembers };
      }
      return group;
    });
    setGroups(updatedGroups);
  };

  const handleOptInChange = (groupIndex, memberIndex, value) => {
    // Specialized function to handle opt-in checkbox changes
    handleMemberChange(groupIndex, memberIndex, 'optedIn', value);
  };

  const viewOptInDocument = () => {
    // Logic to view the opt-in document, e.g., open a modal or a new tab
    window.open("/Opt-In.html", '_blank');
  };

  return (
    <div className="simple-demo">
      <div className="main-section-header">
        <h2>Demo Settings</h2>
      </div>
      <table>
        <thead>
          <tr>
            <th>Group</th>
            <th>Member Name</th>
            <th>Mobile #</th>
            <th>Opt-in</th>
            <th>Custom Message</th>
          </tr>
        </thead>
        <tbody>
          {groups.map((group, groupIndex) => (
            group.members.map((member, memberIndex) => (
              <tr key={`${groupIndex}-${memberIndex}`}>
                <td>{group.name}</td>
                <td>
                  <input
                    type="text"
                    value={member.name}
                    onChange={(e) => handleMemberChange(groupIndex, memberIndex, 'name', e.target.value)}
                    placeholder="Enter Name"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={member.mobileNumber}
                    onChange={(e) => handleMemberChange(groupIndex, memberIndex, 'mobileNumber', e.target.value)}
                    placeholder="Enter Mobile #"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={member.optedIn}
                    onChange={(e) => handleOptInChange(groupIndex, memberIndex, e.target.checked)}
                  />
                </td>
                <td>{group.customMessage}</td>
              </tr>
            ))
          ))}
        </tbody>
      </table>
      <button onClick={viewOptInDocument}>Review Opt-in Document</button>
    </div>
  );
}

export default Settings;

