function Header() {
  return (
    <header className="header">
      <div className="header-logo-container">
        <img src="/AS-Logo.png" alt="Logo" className="header-logo" />
        <p className="header-tagline">Active Shooter Response</p>
      </div>
      <h1 className="header-title">Liber8Now Command Center</h1>
      <div className="header-login-container">
        <input type="text" placeholder="Login" className="header-input" />
        <input type="password" placeholder="Password" className="header-input" />
      </div>
    </header>
  );
}

export default Header;
