import React from 'react';
// Import Axios or another HTTP client if making API calls from the Dashboard
import axios from 'axios';

function Demo({ groups }) {
  const declareIncident = async () => {
    const incidentDetails = {
      messages: groups.map(group => ({
        message: group.customMessage,
        members: group.members.filter(member => member.optedIn),
      })),
    };

    try {
      //const response = await axios.post('http://localhost:8443/api/declare-incident', incidentDetails, {
      const response = await axios.post('https://server.liber8now.ai/api/declare-incident', incidentDetails, {
        // Adding timeout can be useful for handling slow network responses
        timeout: 10000, // milliseconds
      });
      console.log('Incident declared, response:', response.data);
      // Implement additional UI feedback on success
      alert('Incident successfully declared.');
    } catch (error) {
      console.error('Error declaring incident:', error);
      // Implement user-friendly error feedback
      alert('Failed to declare the incident. Please try again.');
    }
  };

  /*


  const declareIncident = () => {
	  let msg ="INCIDENT DECLARED\n";
    groups.forEach(group => {
          console.log(`Sending "${group.customMessage}" `);
      group.members.forEach(member => {
        if (member.name && member.mobileNumber & member.optedIn) {
          console.log(`Sending "${group.customMessage}" to ${member.name} (${member.mobileNumber}).`);
          // Here you would call your backend service to send an SMS via Twilio
          //alert('Incident declared! ' + group.customMessage + " sent to " + member.name + " at " + member.mobileNumber);
          msg = msg + member.name + ": " + group.customMessage + "\n\n";
	}
      });
    });
	  alert (msg);
  };
*/

  return (
    <div className="dashboard">
      <div className="image-container">
        <img src="/AS-background.png" alt="Large Display" style={{ width: '60vw' }}/>
        <button onClick={declareIncident} className="declare-incident-button">
          <img src="/declareIncident.png" alt="Declare Incident" style={{ width: '20vw' }}/>
        </button>
      </div>
    </div>
  );
}

export default Demo;

