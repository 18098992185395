import React, { useState } from 'react';
import MessageModal from './MessageModal'; 

const defaultGroups = ['Admin Group', 'Staff Group', 'Students Group']; // placeholders

function MessageManagement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [editingMessage, setEditingMessage] = useState(null);

  const startEditing = (message) => {
    console.log ("startEditing Called message: " + message);
    setEditingMessage(message);
    setIsModalOpen(true);
  };

  const startAddNew = () => {
    startEditing (null);
  };

  const deleteMessage = (id) => {
    // logic to delete Message
    console.log ("deleteMessage Called");
  };

  const handleSaveMessage = (messageData) => {
    if (editingMessage) {
      // Edit message logic
      setMessages(messages.map(message => message.id === editingMessage.id ? { ...messageData, id: editingMessage.id } : message));
    } else {
      // Add message logic
      const newMessage = { ...messageData, id: Date.now() }; // Using timestamp for unique ID
      setMessages([...messages, newMessage]);
    }
    setIsModalOpen(false);
    setEditingMessage(null);
  };

  return (
    <div>
      <div className="main-section-header">
        <h2>Message Management</h2>
      </div>
      <div className="add-group-container">
        <button className="add-group-button" onClick={() => startAddNew()}>Add Message</button>
      </div>
      <div className="groups-container">
        <table className="groups-table">
          <thead>
            <tr>
              <th>Default Group</th>
              <th>Message</th>
              <th>Message Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message, index) => (
              <tr key={index}>
                <td>{message.defaultGroup}</td>
                <td>{message.message}</td>
                <td>{message.description}</td>
                <td>
                   <button onClick={() => startEditing(message)}>
                     {/* Replace with an actual edit icon */}
                     ✏️
                  </button>
                  <button onClick={() => deleteMessage(message.id)}>
                    {/* Replace with an actual delete icon */}
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <MessageModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveMessage}
          editMessage={editingMessage}
          defaultGroups={defaultGroups}
        />
      )}
    </div>
  );
}

export default MessageManagement;

