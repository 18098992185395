import React , { useState } from 'react';
import GroupManagement from './GroupManagement';
import UserManagement from './UserManagement';
import MessageManagement from './MessageManagement';
import Settings from './Settings';
import Demo from './Demo';

const defaultGroups = [
  { name: 'Swat Team', customMessage: 'Active Shooter Confirmed at Wilson High School.  Urgent Response Required.', members: [{ name: 'John', mobileNumber: '', optedIn: true }] },
  { name: 'Staff East Wing', customMessage: 'Active Shooter on Campus, Evacuate Immediately.  Exit classroom, turn right, go down stairwell 3, exit building, do not stop', members: [{ name: 'Janelle', mobileNumber: '', optedIn: true }] },
  { name: 'Staff North Wing', customMessage: 'Active Shooter on Campus, Evacuate Immediately.  Exit cafeteria towards auditorium, exit building, do not stop.', members: [{ name: 'Lucy', mobileNumber: '', optedIn: true }] }
];

function MainSection({ activePanel }) {
  const [groups, setGroups] = useState(defaultGroups);


  return (
    <div className="main-section">
      {activePanel === 'manageGroups' && <GroupManagement />}
      {activePanel === 'manageUsers' && <UserManagement />}
      {activePanel === 'manageMessages' && <MessageManagement />}
      {activePanel === 'demo' && <Demo groups={groups} setGroups={setGroups} />}
      {activePanel === 'settings' && <Settings groups={groups} setGroups={setGroups} />}

    </div>
  );
}

export default MainSection;

