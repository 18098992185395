import React, { useState } from 'react';
import GroupModal from './GroupModal';

function GroupManagement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groups, setGroups] = useState([]); // This will be your groups state
  const [editingGroup, setEditingGroup] = useState(null);

  const onSave = (groupData) => {
	  console.log ("OnSave Called group ID: " + groupData.id);
  if (groupData.id) {
    // Update the group
    setGroups(groups.map((group) => group.id === groupData.id ? { ...group, ...groupData } : group));
  } else {
    // Add a new group
    const newGroup = { ...groupData, id: Date.now() }; // Generate a unique id for the new group
    setGroups([...groups, newGroup]);
  }
};


  const startEditing = (group) => {
    console.log ("startEditing Called group: " + group);
    setEditingGroup(group);
    setIsModalOpen(true);
  };

  const startAddNew = () => {
    startEditing (null);
  };

  const deleteGroup = (id) => {
    // logic to delete group
    console.log ("deleteGroup Called");
  };

  const sendMessage = (groupId) => {
    // Implement send message logic
  };

  return (
    <div>
      <div className="main-section-header">
        <h2>Group Management</h2>
      </div>
      <div className="add-group-container">
        <button className="add-group-button" onClick={() => startAddNew()}>Add Group</button>
      </div>
      <div className="groups-container">
        <table className="groups-table">
          <thead>
            <tr>
              <th>Group Name</th>
              <th>Description</th>
              <th>Communication Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <tr key={index}>
                <td>{group.name}</td>
                <td>{group.description}</td>
                <td>{group.communicationType}</td>
                <td>
                   <button onClick={() => startEditing(group)}>
                     {/* Replace with an actual edit icon */}
                     ✏️
                  </button>
                  <button onClick={() => deleteGroup(group.id)}>
                    {/* Replace with an actual delete icon */}
                    🗑️
                  </button>
                  <button onClick={() => sendMessage(group.id)}>
                    {/* Replace with an actual send message icon */}
                    📧
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <GroupModal 
	  isOpen={isModalOpen} 
	  onClose={() => setIsModalOpen(false)} 
	  onSave={onSave}
          editGroup={editingGroup}
      />
    </div>
  );
}


export default GroupManagement;
