import React, { useState, useEffect } from 'react';

function UserModal({ isOpen, onClose, onSave, editUser, defaultGroups }) {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [mobile, setMobile] = useState('');
  const [classroomOrOffice, setClassroomOrOffice] = useState('');
  const [defaultGroup, setDefaultGroup] = useState('');
  const isEditMode = editUser != null;

  useEffect(() => {
    if (isEditMode) {
      setName(editUser.name);
      setTitle(editUser.title);
      setMobile.g(editUser.mobile);
      setClassroomOrOffice(editUser.classroomOrOffice);
      setDefaultGroup(editUser.defaultGroup);
    } else {
      resetForm();
    }
  }, [isEditMode, editUser]);

  const resetForm = () => {
    setName('');
    setTitle('');
    setMobile('');
    setClassroomOrOffice('');
    setDefaultGroup('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave({ name, title, mobile, classroomOrOffice, defaultGroup });
    onClose();
  };

  return isOpen ? (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-header">
          <h2>{isEditMode ? 'Update User: ' + name : 'Add User'}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <form onSubmit={handleSubmit} className="modal-body" id="addUserForm" >
	  <label>
	    User Name:
            <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} required />
	  </label>
	  <label>
	    User Title:
            <input placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} required />
	  </label>
	  <label>
	    User Mobile Phone#:
            <input type="text" placeholder="Mobile Phone Number" value={mobile} onChange={e => setMobile.g(e.target.value)} required />
	  </label>
	  <label>
	    Designated Room:
            <input placeholder="Classroom/Office" value={classroomOrOffice} onChange={e => setClassroomOrOffice(e.target.value)} required />
	  </label>
	  <label>
	    Default Group:
          <select value={defaultGroup} onChange={e => setDefaultGroup(e.target.value)} required>
            <option value="">Select Group</option>
            {defaultGroups.map(group => (
              <option key={group} value={group}>{group}</option>
            ))}
          </select>
	  </label>
        </form>
         <div className="modal-footer">
            <button type="submit" form="addUserForm">
            {editUser ? 'Save' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default UserModal;

