import React, { useState, useEffect } from 'react';

function GroupModal({ isOpen, onClose, onSave, editGroup }) {
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [communicationType, setCommunicationType] = useState('duplex');
  const isEditMode = editGroup != null;

  // Initialize the modal with group data when in edit mode
  useEffect(() => {
    if (isEditMode) {
      setGroupName(editGroup.name);
      setGroupDescription(editGroup.description);
      setCommunicationType(editGroup.communicationType);
    } else {
      resetForm();
    }
  }, [isEditMode, editGroup]);

  const resetForm = () => {
    setGroupName('');
    setGroupDescription('');
  };

  const handleSubmit = (event) => { // suncup w/ UserModal
    event.preventDefault();
    const groupData = {
      name: groupName,
      description: groupDescription,
      communicationType: communicationType
    };
    console.log ("GroupData:" + isEditMode + groupData.name + groupData.description + groupData.communicationType);
    if (isEditMode) {
      onSave({ ...groupData, id: editGroup.id });
    } else {
      onSave(groupData); // onSave should handle both add and save
    }
    onClose();
  };

  return isOpen ? (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-header">
          <h2>{isEditMode ? 'Edit Group' : 'Add Group'}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <form onSubmit={handleSubmit} className="modal-body" id="addGroupForm">
          <label>
            Group Name:
            <input
              type="text"
	      placeholder="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />
          </label>
          <label>
            Group Description:
            <input
              type="text"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
              required
            />
          </label>
          {/* Add your radio buttons or other inputs here */}
          <div className="communication-type-description">
            Allow One-way or Two-way Communication during an Incident
          </div>
          <div className="communication-type-options">
            <label>
              <input
                type="radio"
                name="communicationType"
                value="one-way"
                checked={communicationType === 'One-way'}
                onChange={() => setCommunicationType('One-way')}
              />
	      One-way
            </label>
            <label>
              <input
                type="radio"
                name="communicationType"
                value="two-way"
                onChange={() => setCommunicationType('Two-way')}
              />
	      Two-way
            </label>
	  </div>
        </form>
        <div className="modal-footer">
          <button type="submit" form="addGroupForm">
            {isEditMode ? 'Save' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default GroupModal;

