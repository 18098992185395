import React from 'react';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#f8f8f7', color: '#1d1d1f', padding: '10px', textAlign: 'center' }}>
      <p>© 2024 Liber8Now. All rights reserved.</p>
    </footer>
  );
}

export default Footer;

