import React, { useState } from 'react';
import UserModal from './UserModal'; 

const defaultGroups = ['Admin Group', 'Staff Group', 'Students Group']; // placeholders

function UserManagement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);

  const startEditing = (user) => {
    console.log ("startEditing Called user: " + user);
    setEditingUser(user);
    setIsModalOpen(true);
  };

  const startAddNew = () => {
    startEditing (null);
  };

  const deleteUser = (id) => {
    // logic to delete User
    console.log ("deleteUser Called");
  };

  const handleSaveUser = (userData) => {
    if (editingUser) {
      // Edit user logic
      setUsers(users.map(user => user.id === editingUser.id ? { ...userData, id: editingUser.id } : user));
    } else {
      // Add user logic
      const newUser = { ...userData, id: Date.now() }; // Using timestamp for unique ID
      setUsers([...users, newUser]);
    }
    setIsModalOpen(false);
    setEditingUser(null);
  };

  return (
    <div>
      <div className="main-section-header">
        <h2>User Management</h2>
      </div>
      <div className="add-group-container">
        <button className="add-group-button" onClick={() => startAddNew()}>Add Group</button>
      </div>
      <div className="groups-container">
        <table className="groups-table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Title</th>
              <th>Mobile #</th>
              <th>Office/Classroom </th>
              <th>Default Group</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.title}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.classroomOrOffice}</td>
                <td>{user.defaultGroup}</td>
                <td>
                   <button onClick={() => startEditing(user)}>
                     {/* Replace with an actual edit icon */}
                     ✏️
                  </button>
                  <button onClick={() => deleteUser(user.id)}>
                    {/* Replace with an actual delete icon */}
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <UserModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveUser}
          editUser={editingUser}
          defaultGroups={defaultGroups}
        />
      )}
    </div>
  );
}

export default UserManagement;

