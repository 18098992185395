
import React, { useState, useEffect } from 'react';

function MessageModal({ isOpen, onClose, onSave, editMessage, defaultGroups }) {
  const [defaultGroup, setDefaultGroup] = useState('');
  const [message, setMessage] = useState('');
  const [description, setDescription] = useState('');
  const isEditMode = editMessage != null;

  useEffect(() => {
    if (isEditMode) {
      setDefaultGroup(editMessage.defaultGroup);
      setMessage(editMessage.message);
      setDescription(editMessage.description);
    } else {
      resetForm();
    }
  }, [isEditMode, editMessage]);

  const resetForm = () => {
    setMessage('');
    setDescription('');
    setDefaultGroup('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave({ defaultGroup, message, description});
    onClose();
  };

  return isOpen ? (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-header">
          <h2>{isEditMode ? 'Update Message: ' : 'Add Message'}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <form onSubmit={handleSubmit} className="modal-body" id="addMessageForm" >
	  <label>
	    Default Group:
          <select value={defaultGroup} onChange={e => setDefaultGroup(e.target.value)} required>
            <option value="">Select Group</option>
            {defaultGroups.map(group => (
              <option key={group} value={group}>{group}</option>
            ))}
          </select>
	  </label>
	  <label>
	    Message :
            <input type="text" placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} required />
	  </label>
	  <label>
	    Description:
            <input placeholder="Description" value={description} onChange={e => setDescription(e.target.value)} required />
	  </label>
        </form>
         <div className="modal-footer">
            <button type="submit" form="addMessageForm">
            {editMessage ? 'Save' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default MessageModal;

