import './App.css';
import React, { useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import LeftPanel from './components/LeftPanel';
import MainSection from './components/MainSection';

function App() {
  const [activePanel, setActivePanel] = useState("dashboard"); // default panel

  return (
    <div style={styles.appStyle}>
      <Header />
      <div style={styles.contentStyle}>
        <LeftPanel activePanel={activePanel} setActivePanel={setActivePanel} />
        <MainSection activePanel={activePanel} />
      </div>
      <Footer />
    </div>
  );
}
  // ... styles

const styles = {
  appStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
  contentStyle: {
    display: 'flex',
    flex: 1, // Takes up the remaining space
  }
};

export default App;

