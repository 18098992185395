import React from 'react';

function LeftPanel({ activePanel, setActivePanel }) {
  return (
    <div style={styles.leftPanelStyle}>
      <ul style={styles.listStyle}>
        <li onClick={() => setActivePanel("manageGroups")} style={activePanel === "manageGroups" ? styles.activeItemStyle : styles.itemStyle}>Manage Groups</li>
        <li onClick={() => setActivePanel("manageUsers")} style={activePanel === "manageUsers" ? styles.activeItemStyle : styles.itemStyle}>Manage Users</li>
        <li onClick={() => setActivePanel("manageMessages")} style={activePanel === "manageMessages" ? styles.activeItemStyle : styles.itemStyle}>Manage Messages</li>
        <li onClick={() => setActivePanel("settings")} style={activePanel === "settings" ? styles.activeItemStyle : styles.itemStyle}>Settings</li>
        <li onClick={() => setActivePanel("demo")} style={activePanel === "demo" ? styles.activeItemStyle : styles.itemStyle}>Demo</li>
      </ul>
    </div>
  );
}

const styles = {
  leftPanelStyle: {
    backgroundColor: '#f8f8f7',
    color: '#1d1d1f',
    width: '250px',
    padding: '20px',
    //boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  listStyle: {
    listStyleType: 'none', // Disable bullets
    paddingLeft: 0,        // Remove indentation
  },
  itemStyle: {
    cursor: 'pointer',
    marginBottom: '15px',
    padding: '5px',
    transition: 'background-color 0.3s', // Smooth transition for hover effect
  },
  activeItemStyle: {
    cursor: 'pointer',
    backgroundColor: '#e0e0e0',
    marginBottom: '15px',
    padding: '5px',
  }
};

// Add a hover effect using a separate function
const addHoverEffect = () => {
  const hoverStyle = {
    ...styles.itemStyle,
    ':hover': {
      backgroundColor: '#d0d0d0', // Color change on hover
    },
  };

  return hoverStyle;
};

export default LeftPanel;

